<template>
  <Stack id="layout" tag="main" direction="col" gap="none" align="center" justify="between" class="min-h-screen">
    <div
      class="bg-[#000000] fixed top-none h-screen w-full z-20 opacity-50 cursor-pointer"
      v-if="overlay"
      @click="onOverlayClick"
    />
    <SimpleHeader :title="title" :icon-name="iconName" :subtitle="subtitle" />
    <Stack class="w-full flex-grow">
      <slot />
    </Stack>
    <SimpleFooter />
  </Stack>
</template>
<script lang="ts" setup>
import { SimpleLayoutProps } from "./types";
import SimpleHeader from "@/components/Global/SimpleHeader/SimpleHeader.vue";

const { title, subtitle, description, iconName } = withDefaults(defineProps<SimpleLayoutProps>(), {
  description: "MKM Building Supplies, the UK's largest independent builders' merchant.",
});

// eslint-disable-next-line vue/no-dupe-keys
const { overlay, onOverlayClick } = useUIState();

useHead({
  title: `MKM - ${title}`,
  meta: [
    {
      name: "description",
      content: description,
    },
  ],
});
</script>
